@use "../../../styles/base" as vars;

.redRadio {
    input {
        accent-color: red;
        appearance: none;
        -webkit-appearance: none;
        border-radius: 50%;
        background: #c2bfbf;
        border: 3px solid #FFF;
        /* The outline will be the outer circle */
        outline: 1px solid #999;


        &:checked {
            border-radius: 50%;
            background: red;
            border: 2px solid #FFF;
            /* The outline will be the outer circle */
            outline: 1px solid red;
        }
    }

    &:first-of-type {
        input {
            //accent-color: red;

            &:checked {
                background: red;
                outline: 1px solid red;
            }
        }
    }

    &:not(:first-of-type) {
        input {
            //accent-color: blue;

            &:checked {
                background: vars.$base-color-dark;
                outline: 1px solid vars.$base-color-dark;
            }
        }
    }
}