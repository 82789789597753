@use "../../styles/base" as vars;

.fullScreenForm {       
    position: fixed;
    top: 5rem;
    left: 3rem;
    right: 2rem;
    bottom: 2rem;
    z-index: 500;
    overflow: hidden;
    margin: auto;
    border-radius: 1rem;    
    background-color: #EEEEEE;
    padding: 1rem;
    display: flex;
    flex-direction: column;
}

.innerScroll {
    margin: 0.5rem;
    height: 100%;
    flex-grow: 1;
    overflow: auto;
}

.inner {    
    width: 100%;
    //height: 3000px;
}

.selector {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.stickyButton {
    margin-left: auto;
    min-width: 20rem;
    white-space: nowrap;
    padding: 0.5rem 1rem; // Adjust padding as needed
}
.tabs {
    margin-top: 3rem;
    background-color: #FAFAFA;
}