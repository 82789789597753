button.floating {
    position: fixed;
    bottom: 5rem;
    right: 4rem;
    z-index: 1000;
    background: linear-gradient(to right, #EF37F2, #FF0000) !important;
    border-color: white !important;
    color: white;
    padding: 1rem 2rem;
    font-weight: bold;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover, &:active {
        background: linear-gradient(to right, #C027C2, #CC0000) !important;
        border-color: white;
    }
}

button.regular {
    background: linear-gradient(to right, #EF37F2, #FF0000) !important;
    border-color: white !important;
    color: white;
    padding: 1rem 2rem;
    font-weight: bold;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover, &:active {
        background: linear-gradient(to right, #C027C2, #CC0000) !important;
        border-color: white;
    }
}