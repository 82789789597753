@use "../../styles/base" as vars;
@use "../../styles/table" as table;

.table {
    @extend .tableBase;
}

@media(min-width: vars.$breakpoint-small-screen) {
    .table {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}
