@use "../../styles/base" as vars;

.root {    
    height: 100%;
    display: flex;
    flex-flow: column;
    max-height: 100vh;
}

.header {
    flex: 0 1 auto;
    //background-color: red;    
}

.body {
    flex: 1 1 auto;
    display: flex;
    flex-flow: row;
    //background-color: green;
    max-height: calc(100vh - vars.$navbar-height);
}

.sideMenu {
    flex: 0 1 auto;
    background-color: vars.$navbar-color;
    min-width: vars.$side-menu-width-min;
    max-width: vars.$side-menu-width;
    overflow: auto;
    display: none;
}

.main {
    flex: 1 1 auto;
    background-color: white;
    overflow: auto;
    //padding: 2rem 1rem 1rem 2rem;
}

.mainInner{
    //height: 5000px;
}

@media(min-width: vars.$breakpoint-medium-screen) {
    .sideMenu {
        display: block;
    }
}