@use "../../../styles/base" as vars;
@use "../../../styles/table" as table;

.table {
    @extend .tableBase;

    .requestStatus {
        border-bottom: 1px solid black;
        padding-bottom: 0.3rem;
    }

    .requestNew {
        @extend .requestStatus;
        color: #FF007A;
    }

    .requestInProgress {
        @extend .requestStatus;
        color: #2D7FD0;
    }

    .requestCompleted {
        @extend .requestStatus;
        color: #33AE9F;
    }
}

@media(min-width: vars.$breakpoint-small-screen) {
    .table {
        grid-template-columns: 1fr 3fr 5fr 5fr repeat(6, 3fr);
    }
}
