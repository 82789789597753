@use "../../styles/base" as vars;

.header {
    font-weight: bold;
    font-size: 2rem;
    margin: 2rem 0;
    color: vars.$base-color-dark;
}

.inputWrapper {
    margin: 2rem 0;
}

.input {
    background: #FFFFFF;
    border: 1px solid vars.$base-color-dark;
    border-radius: 0.5rem;    
}

.submitButton {    
    font-size: 1.5rem;
    font-weight: bold;
    border-radius: 0.5rem;    
}

.showPassIcon {
    cursor: pointer;
    border: 1px solid vars.$base-color-dark;
}