@use "../../../styles/base" as vars;
@use "../../../styles/table" as table;

.table {
    @extend .tableBase;
}

.tableNoOperations {
    @extend .tableBase;
}

.actionButtons {
    display: flex;
    flex-direction: row;
    
    //gap: 0.5rem;    

    .actionButton {
        .icon{
            height: 1rem;
            width: 1rem;
            align-items: center;
            justify-content: center;
        }
    }
}

.btnHoverable {
    &:disabled {
        border: none;
    }

    .btnNoHover {
        visibility: visible;
        display: block;
    }

    .btnHover {
        visibility: hidden;
        display: none;
    }

    &:hover {
        .btnNoHover {
            visibility: hidden;
            display: none;
        }

        .btnHover {
            visibility: visible;
            display: block;
        }
    }
}

@media(min-width: vars.$breakpoint-small-screen) {
    .table {
        grid-template-columns: repeat(8, 1fr) 8fr 1fr;
    }

    .tableNoOperations {
        grid-template-columns: repeat(8, 1fr) 8fr;
    }
}


    .numberCell {
        //width: 4rem;
    }

    .checkCell {
        //width: 3rem;
    }

    .tableRow {
        //cursor: pointer;
    }
