@use "../../../styles/base" as vars;

.pageInfo {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;

    .rowsOnPage {
    }

    .totalRows {
        flex-grow: 1;
        display: flex;
        justify-content: end;
    }
}

.borderedPagingControls {
    height: 2rem;
    border: var(--bs-border-width) var(--bs-border-style) vars.$base-color-dark;
    border-radius: 0.25rem;
    background-color: transparent;
    color: vars.$base-color-dark;
}

.buttonArrows {
    @extend .borderedPagingControls;
    width: 2rem;
    
    &:disabled {
        opacity: 0.5;
    }
}

.pageNumberInput {
    @extend .borderedPagingControls;
    width: 3rem;
    padding-left: 0.5rem;
}

.showRowsSelection {
    @extend .borderedPagingControls;
    padding: 0 0.5rem;        
}

.pageControls {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .moveToPageControls {
        flex-grow: 1;
        justify-content: end;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        //d-flex align-items-center gap-3
    }
}

@media(min-width: vars.$breakpoint-large-screen) {
    .pageInfo {
        flex-direction: row;
    }

    .pageControls {
        flex-direction: row;
        align-items: center;

        .moveToPageControls {
            display: flex;
            flex-direction: row;
        }
    }
}