.container {
    /* display: flex;
    flex-direction: column;
    width: 100%;*/
    display: grid;
    grid-template-columns: 1fr;
}

.item {
    width: 100%;
    box-sizing: border-box;    
}
