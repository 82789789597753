@use "../styles/base" as vars;

.header {
    background-color: vars.$base-color-dark;
    height: vars.$main-header-height;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.item {
    flex: 1;
}

.pageName {
    flex: 1;
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.5rem;
}

.mainContainer {
    padding: 1rem 0.5rem 0rem 0.5rem;
}

@media(min-width: vars.$breakpoint-small-screen) {
    .mainContainer {
        padding: 0.5rem 3rem 0rem 3rem;        
    }

}