@use "../../../styles/base" as vars;

.addDocsWrapper{
    display: flex;
    flex-direction: column;
}
.header {
    font-size: 2rem;
    margin-top: 2rem;    
}
.icon {
    height: 5rem;
    margin-top: 3rem;
}

.field {
    margin-top: 1rem;
    width: 20rem;
    display: grid;
}

.fileControl {
    margin-top: 3rem;
    @extend .field;
}

.fileType {
    @extend .field;
}

.dateControl {
    @extend .field;
    > div{
        > div{
            border: none;
        }
    }
}

.description {
    @extend .field;
}

.button {
    @extend .field;
}