@use "../../../styles/base" as vars;

.itemContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.header {
    margin-bottom: 10px;
    background-color: #f0f0f0;
    width: 100%;
    padding: 10px;
    border-bottom: 2px solid #b1d0ee;
}

.textContainer {
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;    
}

.text {
    max-height: 100%;
    max-width: 100%;
}

.bottomText {
    margin-top: 10px;
    background-color: #f0f0f0;
    width: 100%;
    padding: 10px;
    border-top: 2px solid #b1d0ee;
}