@use "../../../styles/base" as vars;

.activeLine {
    background-color: #cfe2ff;
    border-block-color: #cfe2ff;
    color: #084298;
    z-index: 2;
}

.line {
    @extend .lineWithBackground;
    margin-top: 1rem;
    font-size: 1.2rem;
    color: vars.$base-color-dark;
    cursor: pointer;

    &.isGroup {
        background-color: #cff4fc;
    }


}

.lineItem {
    svg {
        margin-right: 1rem;
    }

    margin-right: auto;
    .name{
        display: inline;
    }
}

.editButton {
    float: right;
    margin: 1rem 0;
    font-size: 1rem;
}