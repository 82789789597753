﻿@use "./base" as vars;

.tableBase {
    display: grid;
    grid-template-columns: 1fr;
    //background: linear-gradient(#000,#000) center/2px 100% no-repeat, linear-gradient(#000,#000) center/2px 100% no-repeat;
    //background-position: 33% 0, 67% 0;

    font-size: 1rem;
    color: vars.$base-color-dark;

    a {
        text-decoration: none;
    }

    .headerRow {
        display: none;

        .headerText {
            font-weight: bold;
            padding: 0 1.5rem;
            display: flex;
            justify-content: center;
            flex-direction: column;
            border-right: 1px solid vars.$base-color-dark;
            border-bottom: 1px solid vars.$base-color-dark;

            &:last-child {
                border-right: none;
            }
        }
    }

    .cursorPointer {
        cursor: pointer;
    } 
    
    .fullRow {
        grid-column: 1 / -1;
    }

    .row {
        margin: 0.5rem 0;
        border-radius: 0.5rem;
        background: vars.$text-line-background;
        display: grid;
        grid-template-columns: 1fr 2fr;
        row-gap: 1rem;
        padding: 0.5rem 1rem;
        position: relative;

        .cell {
            display: contents;

            &.hideOnMobile {
                display: none;
            }

            > .innerCell {
                display: contents;
                padding: 0.25rem 1rem;
                min-height: 2rem;


                .headerName {
                    flex: 0;
                    font-weight: bold;
                    font-size: 1rem;
                }

                .value {
                    flex: 1;
                    margin-left: 0.5rem;
                }
            }
        }

        .menu {
            position: absolute;
            top: 0.4rem;
            right: 1rem;
            z-index: 1;
            height: 1rem;
            width: 0.5rem;

            svg {
                height: 1rem;
            }
        }
    }

    .hoverable {
        cursor: pointer;
    }

    .addButton {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #012B54;
        border-radius: 0.5rem;
        font-size: 1.25rem;
        padding: 0.2rem 0;
        margin: 0.5rem 0;
        height: 3rem;
        background-color: vars.$button-color-secondary;

        > svg {
            margin-left: 1rem;
            height: 1.5rem;
            width: 1.5rem;

            &:hover {
                color: inherit;
            }
        }
    }
}

.addNewBtnContainer {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    margin-bottom: 1.5rem;

    .addNewBtn {
        padding: 0.2rem 2rem;
        height: 2rem;
    }
}

@media(min-width: vars.$breakpoint-small-screen) {
    .tableBase {
        grid-template-columns: 1fr 1fr 1fr 5fr 1fr;

        .headerRow {
            display: contents;

            > .headerText {
                //margin: 0.5rem 0;
                padding: 1em 1rem 0.5rem 1rem;

                &.center {
                    .innerCell {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }

        .row {
            display: contents;
            margin: 0;

            .cell {
                display: flex;
                justify-content: center;
                flex-direction: column;
                border-right: 1px solid vars.$base-color-dark;

                &.hideOnMobile {
                    display: flex;
                }


                &:first-child {
                    .innerCell {
                        border-radius: 0.5rem 0 0 0.5rem;
                    }
                }

                &:last-child {
                    border-right: none;

                    .innerCell {
                        border-radius: 0 0.5rem 0.5rem 0;
                    }
                }

                > .innerCell {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    background: vars.$text-line-background;
                    white-space: nowrap;
                    margin: 0.5rem 0 0 0;
                    min-height: 3rem;

                    &.allowWrap {
                        white-space: normal;
                    }

                    > .headerName {
                        display: none;
                    }
                }

                &.center {
                    .value {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }

            .menu {
                display: none;
            }
        }

        .hoverable {
            &:hover {
                .cell {
                    > .innerCell {
                        background-color: #DCEBFF;
                    }
                }
            }
        }
    }
}