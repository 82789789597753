@use "../../../styles/base" as vars;

.ecg12Table{
    display: grid;
    grid-template-columns: 1fr;    
}

.oneChart {
    border: 2px solid vars.$base-color-dark;
    border-radius: 0.5rem;
    margin: 1rem;
    padding: 2rem 1rem 2rem 1rem;
}

.oneChartContainer{
    width: 10rem;   //a dirty hack for highcharts so the responsive design works. Otherwise the chart does not reduce the size
}

@media(min-width: vars.$breakpoint-small-screen) {
    .ecg12Table {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media(min-width: vars.$breakpoint-extraLarge-screen) {
    .ecg12Table {
        grid-template-columns: repeat(3, 1fr);
    }
}
