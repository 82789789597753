@use "../../../styles/base" as vars;
@use "../../../styles/table" as table;

.table {
    @extend .tableBase;
}

.tableNoEdit {
    @extend .tableBase;
}

.editIcon {
    width: 1.5rem;
}

@media(min-width: vars.$breakpoint-small-screen) {
    .table {
        grid-template-columns: 1fr 10fr 10fr 50fr 1fr;
    }

    .tableNoEdit {
        grid-template-columns: 1fr 10fr 10fr 50fr;
    }
}
