﻿$base-color-dark: #012B54;
$base-color-light: #F7FAFF;

$button-color-hover: #2C6FB0;
$button-color-disabled: #8095AA;
$button-color-text-disabled: #FFFFFF;

$button-color-secondary: #E5F0FF;


$breakpoint-small-screen: 576px;
$breakpoint-medium-screen: 768px;
$breakpoint-large-screen: 992px;
$breakpoint-extraLarge-screen: 1200px;

$navbar-height: 4.7rem;

$navbar-color: #E5F0FF;

$side-menu-width: 22rem;
$side-menu-width-min: 23rem;

$side-menu-font-size: 1rem;
$side-menu-padding-tb: 0.875rem;

$main-header-height: calc($side-menu-font-size + $side-menu-padding-tb + $side-menu-padding-tb + 0.25rem);

$text-line-background: #F0F6FE;

.lineWithBackground {
    background-color: $text-line-background;
    border-radius: 0.5rem;
}

//Overriding some properties of the rangeslider
.rangeslider__handle-tooltip{
    width: auto !important;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.waitingDataCircle {
    color: orange;
}

.liveDataCircle {
    color: limegreen;
}