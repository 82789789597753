@use "../../../styles/base" as vars;

.bodyIcon{    
    svg{
        width: 5rem;
        color: black;
    }
}

.lineWithData {
    display: flex;
    align-items: center;
    min-height: 5rem;
    align-items: center;
}

.valueCell{
    font-weight: bold;
}