@use "../../../styles/base" as vars;
@use "../../../styles/table" as table;

.table{
    @extend .tableBase;
}

@media(min-width: vars.$breakpoint-small-screen) {
    .table {      
        grid-template-columns: 1fr 1fr 1fr 5fr 1fr;
    }
}

.btn{
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    >svg{
        height: 1rem;
    }
}