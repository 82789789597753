@use "../../styles/base" as vars;

.sidebar {   
    & ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
}

.line {
    border-radius: 8px 0px 0px 8px;
    font-size: vars.$side-menu-font-size;
    padding-top: vars.$side-menu-padding-tb;
    padding-bottom: vars.$side-menu-padding-tb;
    margin-bottom: 0.5rem;
    height: 3rem;
}

.header {
    @extend .line;
    background: vars.$base-color-dark;
    color: white;
    margin-left: 2rem;
    font-size: 1.25rem;
    line-height: 1.5rem;

    & svg {
        margin-left: 1.6rem;
        margin-right: 1rem;
        align-self: center;
    }

    &.userNameWithChange {
        display: flex;
        flex-direction: row;
        flex: 0 1 auto;
        flex-wrap: nowrap;

        .userName {
            align-self: center;
            flex: 1;
            display: inline;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .large {
            font-size: 1.25rem;
        }
    }

    &.subexpert {
    }

    &:not(.first) {
        //margin-top: 3rem;
    }

    .changeUser {
        float: right;
    }
}

.subexpert {
    margin-left: 2.75rem;
}

.headerTemp {
    @extend .line;
    background: vars.$base-color-dark;
    color: white;
    margin-left: 4.5rem;
    margin-top: 1rem;

    & svg {
        margin-left: 1.6rem;
        margin-right: 1rem;
    }
}
.item {
    @extend .line;
    background: #FFFFFF;
    margin-left: 4.75rem;
    padding-left: 1.5rem;

    & a {
        color: vars.$base-color-dark;
    }

    &.active {
        margin-left: 3.75rem;
        box-shadow: 2px 2px 3px rgba(1, 43, 84, 0.25);
    }

    & svg {
        margin-right: 1rem;
        height: 1.125rem;
    }

    &.nextLevel {
        margin-left: 5.5rem;
    }
}

.menuSelector {
    height: 3rem;
    margin: 0 0 0.5rem 0;
    //margin-bottom: 0.5rem;
    //margin-left: 0;

    font-size: 1.25rem;
    line-height: 1.5rem;
    display: flex;
    flex-direction: row;
    flex: 1 auto;
    flex-wrap: nowrap;
    padding-top: 0.2rem;
    background: vars.$base-color-dark;
}

.selector {
    margin: 0 0.5rem 0 2rem;
    flex: 1 1;
}