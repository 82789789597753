@use "../../../styles/base" as vars;
@use "../../../styles/table" as table;

.consultationGrid {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    gap: 1rem;
    border: 1px solid darkblue;
    border-radius: 8px;
    padding: 1rem 2rem;
}

.consultationColumn {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}