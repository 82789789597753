@use "../../../styles/base" as vars;
@use "../../../styles/form" as form;

.line {
    background: vars.$text-line-background;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
    color: vars.$base-color-dark;
    padding: 0.5rem 2rem;
    font-size: 1.1rem;
}

.header{
    font-weight: bold;
}
.textCol {
    //display: flex;
    font-weight: bold;

    .textValue {
        //    flex-grow: 1;
        //    margin-left: 1rem;
        font-weight: normal;
        display: inline;
        white-space: pre-wrap;
        word-wrap: break-word;
        min-width: 0;
    }
}

.saveButton {
    background-color: vars.$button-color-secondary;
}
