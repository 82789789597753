.gridContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
}

.column {
    display: flex;
    flex-direction: column;    
}
