@use "../../styles/base" as vars;

.navSimple {
    background-color: vars.$navbar-color;
    border-bottom: 2px solid vars.$base-color-dark;
}

.navMain {
    @extend .navSimple;
    height: vars.$navbar-height;

    .sideMenuToggleBtn {
        display: none;
    }

    .offCanvasToggleBtn {
        display: block;
    }

    .accountIcon {
        height: 2rem;
        width: 2rem;
        color: vars.$base-color-dark;
    }

    .informationIcon {
        height: 1.7rem;
        width: 1.7rem;
        color: vars.$base-color-dark;
    }

    .accountName {
        font-weight: bold;
        font-size: 1.2rem;
        color: vars.$base-color-dark;
    }

    .navAccountMargin {
        margin-right: 1rem;
    }
}

.navBrandTitle {
    font-size: 2rem;
    font-weight: bold;
    color: vars.$base-color-dark !important;
    display: none;
}

.userProfile{
    >a{
        font-weight: bold;
        font-size: 1.2rem;
    }
}

@media(min-width: vars.$breakpoint-medium-screen) {
    .navBrandTitle {
        display: block;
    }

    .navMain {
        .sideMenuToggleBtn {
            display: block;
        }

        .offCanvasToggleBtn {
            display: none;
        }

        .navAccountMargin {
            margin-right: 3rem;
        }
    }
}