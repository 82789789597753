﻿@use "./base" as vars;

.editCell {
    margin-bottom: 1rem;

    .header {
        font-size: 1.25rem;
        font-weight: bold;
    }

    .input, select {
        min-height: 2rem;
    }

    .calendarInput {
        min-height: 2rem;

        > div {
            border: none;
        }
    }
}

.wideBtn {
    padding: 0.35rem 2rem;
}

.buttonRow {
    margin-bottom: 1rem;
}

@media(min-width: vars.$breakpoint-small-screen) {
   
}