@use "../../styles/base" as vars;

.root {
    background-image: linear-gradient(to bottom, vars.$navbar-color, #FFFFFF);
    height: 100%;
    display: flex;
    flex-flow: column;
}

.header {
    flex: 0 1 auto;
}

.main {
    flex: 1 1 auto;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.authBox {
    background: vars.$base-color-light;
    border: 2px solid #BAC7D3;
    box-shadow: 1px 1px 3px rgba(1, 43, 84, 0.25);
    border-radius: 1rem;
    padding: 2rem;
}


