
.mainHeaderPadding {
    padding: 1rem 2rem 2rem 2rem;
}

.mainHeaderLine {    
    &:not(:last-child) {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 2rem; // Adjust the left position as needed
            right: 2rem; // Adjust the width as needed
            border-bottom: 2px solid #81B7FF; // Adjust the color and style as needed
        }
    }
}
