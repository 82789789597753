html {
    height: 100%;
}

body {
    font-family: 'Arimo', sans-serif;
    height: 100%;
}

#root {
    height: 100%;
}

@font-face {
    font-family: 'Arimo';
    src: local('Arimo'), url(./fonts/Arimo/Arimo-Bold.ttf) format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'Arimo';
    src: local('Arimo'), url(./fonts/Arimo/Arimo-BoldItalic.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Arimo';
    src: local('Arimo'), url(./fonts/Arimo/Arimo-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Arimo';
    src: local('Arimo'), url(./fonts/Arimo/Arimo-Regular.ttf) format('truetype');    
}

@font-face {
    font-family: 'Arimo';
    src: local('Arimo'), url(./fonts/Arimo/Arimo-SemiBold.ttf) format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Arimo';
    src: local('Arimo'), url(./fonts/Arimo/Arimo-SemiBoldItalic.ttf) format('truetype');
    font-weight: 600;
    font-style: italic;
}



/*body{
    padding-top: 55px;
}*/


/* Provide sufficient contrast against white background */
/*a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

*/


/*Login form*/

/*html,
body {
    height: 100%;
}

body {
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #f5f5f5;
}
*/
/*.form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
}

    .form-signin .checkbox {
        font-weight: 400;
    }

    .form-signin .form-floating:focus-within {
        z-index: 2;
    }

    .form-signin input[type="email"] {
        margin-bottom: -1px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .form-signin input[type="password"] {
        margin-bottom: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
*/