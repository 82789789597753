@use "../../styles/base" as vars;
@use "../../styles/table" as table;

.table {
    @extend .tableBase;
}

.tableNoEdit {
    @extend .tableBase;
}

.btn {
    background: none;
    border: none;
    padding: 0;
    margin: 0;

    > svg {
        height: 1rem;
    }
}

.editIcon {
    width: 1.5rem;
}

@media(min-width: vars.$breakpoint-small-screen) {
    .table {
        grid-template-columns: 2fr 5fr 5fr 3fr 1fr 1fr 1fr;
    }

    .tableNoEdit {
        grid-template-columns: 2fr 5fr 5fr 3fr 1fr 1fr;
    }
}
