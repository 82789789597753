.headerTable {
    display: flex;
    justify-content: space-between;
}

.headerItem {
    flex: 1;
    text-align: center;
    border: 2px solid #b1d0ee;
    border-right: none;
    //padding: 10px;

    &:last-child {
        border-right: 2px solid #b1d0ee;
    }
}
