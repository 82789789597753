.settingsUserList {
    height: calc(100vh - 10rem);
    overflow: auto;

    .user_tree {
        overflow: auto;
    }
}    

.settingsPermissions {
    overflow: auto;
}
